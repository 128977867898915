/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-promise-executor-return */

import * as Sentry from "@sentry/vue";
import { categorizeError } from "../methods/utils/errorUtils";
import errorSeverityLevels from "../constants/errorSeverityLevels";
import errorFeatureIds from "../constants/errorFeatureIds";
import getEnvironmentTags from "../methods/utils/environmentTags";
import notificationsStore, { NotificationTypes } from "../stores/notifications";
import { i18n } from "../i18n";

/**
 * Default configuration options for error tracking.
 * These can be overridden per function call.
 */
const defaultOptions = {
  /** Whether to rethrow the error after processing. Usually true in production. */
  rethrow: true,

  /**
   * Default error severity level for Sentry.
   * Used to categorize issues by impact (e.g., error, warning, info).
   */
  severity: errorSeverityLevels.error,

  /** Enable console logging in development environments */
  debugLog: process.env.NODE_ENV !== "production",

  // Whether to hide function arguments in error reports
  hideArguments: false,

  /**
   * Function to transform errors before displaying to users.
   * Useful for converting technical errors to user-friendly messages.
   */
  errorTransform: (error) => error,

  /**
   * Custom fingerprinting strategy for Sentry.
   * Helps group similar errors together in the Sentry dashboard.
   */
  fingerprint: null,

  /** Whether to show Sentry's user feedback dialog after an error */
  shouldCollectUserFeedback: false,

  /** Whether to track successful operations */
  trackSuccess: false,

  /** Whether to measure performance using Sentry's tracing */
  measurePerformance: false,

  /**
   * Feature identifier for the operation.
   * Helps categorize errors by feature area.
   */
  feature: errorFeatureIds.unknown,

  /**
   * Handles notification display for user-facing errors.
   */
  notification: null, // { title: i18n string, type: 'error', isFirebaseError: false },

  onFailFn: null, // Function to call on error

  finalFn: null, // Function to call after error handling

  /**
   * Tags provide additional context for error tracking.
   * These are indexed and searchable in Sentry.
   */
  tags: {
    // Technical context from environment
    ...getEnvironmentTags(),

    // Customer tier for business context
    userTier: null, // Possible values: 'free' | 'premium' | 'enterprise'

    // Geographic region for request routing
    region: "eu", // Possible values: 'eu' | 'us' | 'asia'

    // API operation context
    method: null, // HTTP method: 'GET' | 'POST' | etc.

    // Request payload size category
    dataSize: null, // Possible values: 'small' | 'large'
  },
};

/**
 * Higher-order function that wraps an async function with error tracking and performance monitoring.
 *
 * @param {Function} fn - The async function to wrap
 * @param {Object} options - Configuration options that override defaults
 * @returns {Function} Wrapped function with error tracking
 */
export const handleErrorTracking = (fn, options = {}) => async (...args) => {
  const localContext = {
    trackingData: {},
    catchReturnValue: null,
    updateTracking(newData) {
      this.trackingData = { ...this.trackingData, ...newData };
    },
  };
  const boundFn = fn.bind(localContext);

  if (process.env.NODE_ENV === "development") {
    const result = boundFn(...args);

    options?.finalFn?.();

    return result;
  }

  // Merge provided options with defaults
  const config = {
    ...defaultOptions,
    ...options,
    tags: {
      ...defaultOptions.tags,
      ...options.tags,
    },
  };

  // Set initial tags
  Sentry.setTags(config.tags);

  /**
   * Creates and sets up the error context object for Sentry reporting.
   * Includes tags, extra data, severity level, and fingerprinting.
   */
  const setupErrorContext = (error, errorCategory, localFunctionContext) => {
    const errorContext = {
      tags: {
        feature: config.feature,
        errorCategory,
        environment: process.env.NODE_ENV,
        ...config.tags,
      },
      extra: {
        // Safely stringify function arguments, handling circular references
        arguments: config.hideArguments ? null : args.map((arg) => (typeof arg === "object"
          ? JSON.parse(JSON.stringify(arg, (_, v) => (v?.constructor?.name === "File" ? "File" : v)))
          : arg)),
        timestamp: new Date().toISOString(),
        ...(typeof config.extraData === "function"
          ? config.extraData(args)
          : config.extraData),
        ...localFunctionContext,
      },
      level: config.severity,
      fingerprint: config.fingerprint?.(error) || [
        error.name,
        config.feature,
        errorCategory,
      ],
    };

    // Set context information directly on Sentry
    Sentry.setTags(errorContext.tags);
    Sentry.setExtras(errorContext.extra);

    return errorContext;
  };

  // Configure the performance span with name and attributes
  const spanOptions = {
    name: config.feature,
    op: config.operation || "function",
    attributes: {
      feature: config.feature,
      ...config.tags,
    },
  };

  // Start a new performance span using Sentry's tracing API
  return Sentry.startSpan(
    spanOptions,
    async (span) => {
      try {
        // Execute the wrapped function
        const result = await boundFn(...args);

        // Track successful operations if enabled
        if (config.trackSuccess) {
          Sentry.addBreadcrumb({
            category: config.feature,
            message: "Operation completed successfully",
            level: "info",
          });

          // Mark the span as successful
          span.setStatus("ok");
        }

        return result;
      } catch (error) {
        // Add notification if error is user-facing
        if (config.notification) {
          const notificationsStoreInstance = notificationsStore();
          notificationsStoreInstance.addNotification({
            title:
              config.notification.isFirebaseError
                ? i18n.global.t(`others.errors.firebase.${error.code}`)
                : i18n.global.t(config.notification.title || "others.errors.messages.unknownError"),
            type: config.notification.type || NotificationTypes.Error,
          });
        }

        // Categorize the error for better grouping
        const errorCategory = categorizeError(error);
        const transformedError = config.errorTransform(error);

        // Set up error context
        const errorContext = setupErrorContext(transformedError, errorCategory, localContext.trackingData);

        // Log error details in development
        if (config.debugLog) {
          console.error(`[${config.feature}] Error:`, {
            error: transformedError,
            context: errorContext,
          });
        }

        // Update span with error information
        span.setStatus("error");
        span.setAttribute("error.type", transformedError.name);
        span.setAttribute("error.message", transformedError.message);
        span.setAttribute("error.category", errorCategory);

        // Report error to Sentry
        const eventId = Sentry.captureException(transformedError);

        // Show feedback dialog if enabled
        if (config.shouldCollectUserFeedback) {
          Sentry.showReportDialog({ eventId });
        }

        // Handle retries for network errors
        if (config.retry && errorCategory === "network") {
          const retryCount = (args._retryCount || 0) + 1;

          if (retryCount <= config.retry.maxAttempts) {
            await new Promise((resolve) => setTimeout(resolve, config.retry.delay * retryCount));
            handleErrorTracking(fn, config)(
              ...args.map((arg) => (arg._retryCount ? { ...arg, _retryCount: retryCount } : arg)),
            );
          }
        }

        // Call the provided failure handler
        if (config.onFailFn) config.onFailFn(error);

        // Rethrow the error if development mode
        if (process.env.NODE_ENV !== "production") {
          throw transformedError;
        }

        return localContext.catchReturnValue;
      } finally {
        // Call the final function after error handling
        if (config.finalFn) config.finalFn();
      }
    },
  );
};
